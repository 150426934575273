import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import FixScrollJumpLayout from 'components/FixScrollJumpLayout'
import Meta from 'components/meta'
import LandingPage from 'organisms/LandingPage'

export default function Page({ data }) {
  const metadata = get(data, 'site.metadata')
  return (
    <FixScrollJumpLayout>
      <Meta site={metadata} />
      <LandingPage />
    </FixScrollJumpLayout>
  )
}

export const query = graphql`
  query LandingPageQuery {
    site {
      metadata: siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`
