import React from 'react'
import classNames from 'classnames'
import TransitionLink, { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import { motion } from 'framer-motion'

import { PAGE_TRANSITION_DURATION } from 'components/layout'
import styles from './style.module.scss'
import Icon from 'components/icon'

const exit = {
  length: PAGE_TRANSITION_DURATION.slide,
  trigger() {
    const scrollElement = document.querySelector('.tl-edges')
    scrollElement.style.overflow = 'hidden'
  },
}
const entry = {
  length: PAGE_TRANSITION_DURATION.slide,
  trigger() {
    const scrollElement = document.querySelector('.tl-edges')
    scrollElement.scrollTo(0, 0)
    setTimeout(() => {
      scrollElement.style.overflow = null
    }, PAGE_TRANSITION_DURATION.slide * 1000)
  },
}

export default function LandingPage() {
  const goToNextPage = useTriggerTransition({
    to: '/products/',
    replace: true,
    exit,
    entry,
  })

  // page transition
  const transitionState = useTransitionState()
  const variants = {
    entered: { y: '0%' },
    exiting: { y: '-100%' },
    exited: { y: '-100%' },
  }
  const transition = {
    duration: PAGE_TRANSITION_DURATION.slide,
    ease: 'easeInOut',
  }

  return (
    <motion.div
      className="p-5 d-flex flex-column bg-primary text-white ms0 ms1-sm justify-content-between"
      style={{ minHeight: '100%' }}
      onWheel={goToNextPage}
      onTouchMove={goToNextPage}
      initial={false}
      animate={transitionState.transitionStatus}
      variants={variants}
      transition={transition}
    >
      <header className="d-flex">
        <Link to="/about/" className="mr-auto">
          เกี่ยวกับ
        </Link>
        <Icon.Svg
          name="logo"
          fill="white"
          className={styles.logo}
          style={{ position: 'relative', transform: 'translateY(-16px)' }}
        />
        <Link to="/products/" className="ml-auto text-right">
          สินค้า
        </Link>
      </header>
      <main
        className={`text-center ms1 ms2-lg ms3-xl ${styles.hero}`}
        style={{ textTransform: 'uppercase', lineHeight: 1.4 }}
      >
        High performance
        <br />
        stainless steel
        <span className="hero-background">JS Stainless</span>
      </main>
      <footer className="position-relative">
        <div className="text-center">
          <ScrollToDiscover />
        </div>
        <Link to="/contact/">ติดต่อ</Link>
      </footer>
    </motion.div>
  )
}

/**
 *
 * @param {object} props
 * @param {any} props.children
 * @param {string} props.to
 * @param {string=} props.className
 */
function Link({ children, to, className }) {
  const hackStyleToMakeItCenterInFlex = {
    width: '5em',
  }
  return (
    <div className={className} style={hackStyleToMakeItCenterInFlex}>
      <TransitionLink className="inverse" to={to} replace entry={entry} exit={exit}>
        {children}
      </TransitionLink>
    </div>
  )
}

function CoolAnimatedLine({ style }) {
  return (
    <div className={styles.flip}>
      <div className={styles.animatedLine} style={style}></div>
    </div>
  )
}

/**
 *
 * @param {object} props
 * @param {string} [props.className]
 */
function ScrollToDiscover({ className }) {
  return (
    <div className={classNames('d-inline-block', className)}>
      <CoolAnimatedLine style={{ margin: '0 auto 16px' }} />
      <p>Scroll to discover</p>
      <Icon.Svg name="gate" fill="white" style={{ width: 100 }} />
    </div>
  )
}
